@import '~antd/dist/antd.less';
#root {
    font-family: 'Be Vietnam Pro';
}
.ant-collapse-header-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #212b36;
}
.ant-collapse-content-box p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 0px;
}
.ant-col {
    min-height: 0px;
}

ol {
    padding-left: 15px;
}
.ant-form-item-label label {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.85);
}
.ant-input-number-in-form-item {
    width: 100% !important;
    height: 44px !important;
}
.ant-picker {
    width: 100%;
    height: 44px;
}

.btnSubmit {
    padding: 11px 22px;
    gap: 8px;
    border: #ec1c2a;
    width: 110px;
    height: 48px;
    background: #ec1c2a;
    border-radius: 8px;
    color: #fff;
}

.btnBack {
    padding: 11px 22px;
    gap: 8px;
    width: 109px;
    height: 48px;
    border: 1px solid #e9ebf0;
    background-color: #fff;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.45);
}
.ant-input-number-input,
.ant-select-selector {
    width: 524px !important;
    height: 44px !important;
}
.ant-form-item {
    margin-bottom: 16px;
}
.ant-form-item-explain-error {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ff4d4f;
    padding: 0 16px;
}
.ant-upload-drag {
    width: 653px !important;
    height: 240px !important;
}

.ant-upload-drag-container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    height: 100% !important;
}
.ant-upload.ant-upload-drag {
    height: 240px !important;
}
.ant-upload.ant-upload-drag .ant-upload {
    padding: 0px !important;
}
.ant-upload-list {
    width: 653px;
}
.ant-select-selection-placeholder,
.ant-select-selection-item {
    display: flex;
    align-items: center;
}

.ant-collapse-borderless {
    background-color: #f5f6f9;
}
.ant-collapse-item-active {
    background-color: #fff;
    box-shadow: 0px 20px 40px -4px rgba(145, 158, 171, 0.16);
    border-radius: 8px;
}
.ant-upload-list {
    width: 653px;
    max-height: 300px;
    overflow: auto;
}
@media screen and (max-width: 739px) {
    .indemnify .ant-row {
        margin: 0px 8px 80px 8px !important;
    }
    .ant-select-selector,
    .ant-input-number-input {
        width: auto !important;
    }
    .ant-upload-drag {
        width: auto !important;
        height: auto !important;
    }
    .ant-upload-drag-container {
        display: flex;
        flex-direction: column !important;
    }
    .ant-upload-list {
        width: auto !important;
    }
}
@media screen and (min-width: 740px) and (max-width: 1023px) {
    .indemnify .ant-row {
        margin: 60px 18px 80px 18px !important;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
    .indemnify .ant-row {
        margin: 60px 28px 80px 28px !important;
    }
}
@media screen and (min-width: 1200px) {
    .indemnify {
        margin: 60px auto 80px auto !important;
        width: 1140px;
    }
}

@primary-color: #EC1C2A;@border-radius-base: 4px;