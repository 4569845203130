body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Be Vietnam Pro", sans-serif;

  /* max-width: 1440px; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
